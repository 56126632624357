$(document).ready(function() {
   var $header = $('nav#nav'), 
        scrolling = false,
        previousTop = 0,
        currentTop = 0,
        scrollDelta = 5,
        scrollOffset = 50

    $(window).on('scroll', function(){
        if (!scrolling) {
            scrolling = true

            if (!window.requestAnimationFrame) {
                setTimeout(autoHideHeader, 250)
            }
            else {
                requestAnimationFrame(autoHideHeader) 
            }
        }
    })

    function autoHideHeader() {
        var currentTop = $(window).scrollTop()

        if (previousTop - currentTop > scrollDelta) {
            // Scrolling up
            $header.addClass('translateShow')
        }
        else if (currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
            // Scrolling down
            $header.removeClass('translateShow');
        }
        if (currentTop > scrollOffset) {
            $header.addClass('fixed')
        } else {
            $header.removeClass('fixed');
            if($header.hasClass('translateShow')) {
                $header.removeClass('translateShow');
            }
        }

        previousTop = currentTop
        scrolling = false
    }


    $("nav#nav input").change(function(event) {
        if ($(this).is(':checked')) {
            $("body").addClass('menuOpen');
        } else {
            $("body").removeClass('menuOpen');

        }
    }); 

    // Lightbox setting

    $('a[data-rel^=lightcase]').lightcase({
      maxHeight: 1000,
      maxWidth: 1400,
      swipe: true 
    });

    // Smooth scroll

    $('a[href^="#"]').on('click', function(event) {
        var target = $(this.getAttribute('href'));
        if( target.length ) {
            event.preventDefault();
            $('html, body').stop().animate({
                scrollTop: target.offset().top
            }, 500);
        }
    });

    // Menu 

    $('#menuToggle a[href^="#"]').on('click', function(event) {
        $( "#menuToggle input[type='checkbox']" ).prop( "checked", false );
    });

    // Gallery row 

    $(".galleryWrap").justifiedGallery({
        rowHeight:160
    });

    $('.banner-hp').slick({
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
        speed: 500,
        fade: true,  
        arrows: false,  
    });

    /* Modals */

    function modalTrigger(modalSelector) {
        var openModal = $(this).data('modal');
        $('body').addClass('modalOpen')
        $('[data-modal-for='+ modalSelector +']').addClass('modalShown');
    }

    $(document).click(function(event) {
      /*if ((!$(event.target).closest("*[data-modal-for] .modalBox").length) || ($(event.target).attr('class') == 'modalClose')) {*/
      if (!$(event.target).closest("*[data-modal],*[data-modal-for] .modalBox").length) {
        $("body").find("*[data-modal-for]").removeClass("modalShown");
        $("body").removeClass("modalOpen");
      } else if ($(event.target).hasClass('modalClose')) {
        $("body").find("*[data-modal-for]").removeClass("modalShown");
        $("body").removeClass("modalOpen");
      }
    });

    $('*[data-modal]').on('click', function(e) {
      e.preventDefault();
      var openModal = $(this).data('modal');
      modalTrigger(openModal);
    });

    // Own

    // Animate HP 
    var bannerImage = $('.banner__slick-wrapper');
    var afterLine = $('.banner .alterAfter');
    var description = $('.banner .left-site');
    var header = $('header');
    var scrollBtn = $('a.scrollDown');

    var tl = new TimelineMax();
    tl.fromTo(bannerImage, .7, {x:200}, {opacity: 1, x: 0}).from(description, .6, {opacity: 0, y: 100}).from(afterLine, .6, {width: '0%'}, "-=0.6").from(scrollBtn, .6, {opacity:0, y: -100}).from(header, .6, {opacity: 0}, "-=1");



    // Lazy load 

    // window.lazySizesConfig = {
    //     addClasses: true
    // };

    
    $(".artGallery").slick({
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        variableWidth: true
    });


    $(".showEle").click(function(event) {
        $(this).find('.showMe').slideToggle();
    });

});